import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import CloseButton from '../close-button';
import UnstyledButton from '../unstyled-button';
import {badgeStyles} from './styles';
import type {CloseButtonProps} from '../close-button';
import type {UnstyledButtonProps} from '../unstyled-button';
import type {BadgeVariantProps} from './styles';

export type BadgeProps = Omit<UnstyledButtonProps, 'prefix'> &
	BadgeVariantProps & {
		disabled?: boolean;
		prefix?: ReactNode;
		suffix?: ReactNode;
		dismissable?: boolean;
		onDismiss?: () => void;
	};

const Badge = forwardRef<HTMLElement, BadgeProps>(
	(
		{
			as,
			interactive,
			dismissable,
			prefix,
			suffix,
			size,
			color,
			onClick,
			onDismiss,
			disabled,
			children,
			className,
			...props
		},
		forwardedRef,
	) => {
		const interactionEnabled = interactive || dismissable;

		return (
			<UnstyledButton
				{...props}
				as={as ?? (interactionEnabled ? 'button' : 'div')}
				className={twMerge(badgeStyles({size, color, interactive: interactionEnabled, disabled}), className)}
				disabled={disabled || !interactionEnabled}
				onClick={event => {
					onClick?.(event);
					onDismiss?.();
				}}
				ref={forwardedRef}
				tabIndex={interactionEnabled ? 0 : undefined}
			>
				{prefix}
				{children}
				{suffix}
				{dismissable && (
					<CloseButton
						as="div"
						circular
						color={color as CloseButtonProps['color']}
						onPress={onDismiss}
						size="xs"
					/>
				)}
			</UnstyledButton>
		);
	},
);

Badge.displayName = 'Badge';

export default Badge;

export * from './styles';
